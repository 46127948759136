<template>
    <div class="actciles-content">
        <app-menu @onActive="onActive" @onTagsActive="onTagsActive" :isActciles="true"></app-menu>
        <div class="actciles-main">
            <div class="actciles-list">
                <div class="actciles-item" v-for="(item,index) in lists" @click="toPath(`/actciles/${item.id}/detail`)">
                    <div class="actciles-item-img">
                        <img :src="item.img" v-if="item.img"/>
                    </div>
                    <div class="actciles-item-info">
                        <div class="actciles-item-title">{{ item.title }}</div>
                        <div class="actciles-item-intro">
                            {{ item.intro }}
                        </div>
                        <div class="actciles-item-desc">
                            <div class="actciles-item-time">{{ item.createtime }}</div>
                            <div class="actciles-item-author">作者 {{ item.author }}</div>
                            <div class="actciles-item-view">浏览 {{ item.click_nums }}</div>
                            <div class="actciles-item-label">
                                <span v-for="(arr,i) in item.tags">{{ arr.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <no-data v-if="lists.length<=0"/>
                <div class="actciles-pagination" v-if="lists.length>0">
                    <el-pagination
                            background
                            layout="prev, pager, next, jumper"
                            :page-count="total"
                            @current-change="currentChange">
                    </el-pagination>
                </div>
            </div>

            <div class="actciles-hot" :style="{top: scrollTop+'px'}">
                <h3>热门文章</h3>
                <div class="actciles-hot-list">
                    <div class="actciles-hot-item" v-for="(item,index) in listHot" @click="toPath(`/actciles/${item.id}/detail`)">
                        <strong>{{ index+1 }}</strong><p>{{ item.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Search from '@/utils/search'
    export default {
        data() {
            return {
                current: 1,
                total: 0,
                class: null,
                tags: [],
                keyword: this.$route.query.keyword,
                lists: [],
                listHot: [],
                scrollTop: 0
            }
        },
        beforeDestroy() {
            Search.$off("search");
        },
        mounted() {
            Search.$on('search',data => {
                // console.log(data)   //{show:true}
                this.keyword = data.keyword;
                this.page = 1;
                this.getList();
            })
            this.getList()
            this.getListHot()
            window.addEventListener("scroll",this.scrollFun,true);
        },
        methods: {
            onActive(i) {
                if(i){
                    this.class = i
                }else {
                    this.class = null
                }
                this.getList();
            },
            onTagsActive(tags) {
                this.tags = tags;
                this.getList();
            },
            getList() {
                if(this.$route.query.tag_id) {
                    this.tags.push(this.$route.query.tag_id)
                }
                this.$axios.post(this.apiUrl.information, {
                    class: this.class,
                    keyword: this.keyword,
                    page: this.current,
                    tags: this.tags.join(','),
                }).then(res => {
                    this.lists = res.data.list;
                    this.total = res.data.allpage;
                })
            },
            getListHot() {
                this.$axios.post(this.apiUrl.informationHot, {
                    type: 2
                }).then(res => {
                    this.listHot = res.data;
                })
            },
            currentChange(e) {
                document.documentElement.scrollTop = 0

                this.current = e;
                this.getList();
            },
            toPath(url) {
                this.$router.push({
                    path: url
                })
            },
            scrollFun() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop >= 460) {
                    this.scrollTop = scrollTop-460
                } else {
                    this.scrollTop = 0
                }
            }
        }
    }
</script>

<style scoped>

</style>
